.event-management {
	height: 91vh;
	width: 90vw;
}

.event-sidebar {
	flex: 0.931;
	display: flex;
	flex-direction: column;
	padding: 1rem;
	margin-top: -9vh;
}

.event-logo {
	margin-top: 0.5rem;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 2rem;
}

.event-logo > img {
	height: 50px;
}

.event-items {
	display: flex;
	flex-direction: column;
}

.event-items div {
	padding: 0.7rem;
	color: var(--heading);
}

.event-content {
	flex: 5;
	overflow: auto;
}

.event-selected {
	background: linear-gradient(180deg, #4a1e9e 0%, #7745d6 100%);
	color: white !important;
	border-radius: 8px;
	font-weight: 500;
}

.event-selected svg path {
	color: white !important;
}

.white-svg > path {
	fill: white !important;
}
