.navbar2 {
	height: 9vh;
}
.navbar2-left {
	flex: 1;
}
.navbar2-right {
	flex: 5;
}
.avatar-div2 > img {
	height: 35px;
	width: 35px;
	object-fit: contain;
}
.no-avatar2 {
	background-color: white;
	height: 40px;
	width: 40px;
	object-fit: contain;
	border-radius: 15px;
	font-size: x-large;
	display: flex;
	align-items: center;
	justify-content: center;
}
