@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;500;600;700;800&family=Roboto:wght@300;400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");

:root {
	--dark-purple: #4a1e9e;
	--mid-purple: #8a2be2;
	--mid-purple2: #7c3aed;
	--light-purple: #efe7ff;
	--light-purple2: #ddcbff;
	--orange: #fb8500;
	--yellow: #ffb703;
	--light-gray: #6b7280;
	--mid-gray: #374151;
	--dark-gray: #111827;
	--dark-gray2: #0a0d15;
	--heading: #4e4e4e;
	--description: #7d7d7d;
	--card-bg: #ededed;
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
		"Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
		"Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

.dark-purple {
	background-color: #8a2be2 !important;
}

.dark-purple-text {
	color: #8a2be2 !important;
}

.light-purple-bg {
	background: #efe7ff !important;
}

.newsletter-bg {
	background: #4a1e9e !important;
}
