.body {
	margin: 0rem;
	padding: 3rem 5rem;
	align-items: center;
	text-align: flex-start;
}
.tos-nav {
	height: 5rem;
	background-color: #fff;
}

.body h1 {
	/* color: 44ee4e; */
	color: #000000;
	font-size: large;
	font-weight: bold;
}
