.Security {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.security-form {
	background: rgba(255, 255, 255, 0.7);
	border-radius: 10px;
	width: 600px;
	font-weight: 500;
	font-size: 16px;
}
.securityform-div input {
	font-weight: 500;
	font-size: 15px;
	color: #2b2b2b;
}
.securityform-div label {
	font-weight: 500;
	font-size: 15px;
	color: #2b2b2b;
}
