.selected-event_type svg g rect {
	fill: #8a2be2;
}

.selected-event_type svg g path {
	fill: #ede9f5;
	stroke: #ede9f5;
}

.beta-tag {
	background: linear-gradient(99.38deg, #fb8500 30.6%, #fdb704 72.76%);
	transform: rotate(34.5deg);
	font-weight: 600;
	font-size: 8px;
	width: 51.64px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	color: #ffffff;
	top: 5px;
	right: -8px;
}

.soon-tag {
	background: linear-gradient(99.38deg, #fb8500 30.6%, #fdb704 72.76%);
	font-weight: 600;
	font-size: 8px;
	width: 88.01px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	color: #ffffff;
	top: 0;
	border-top-left-radius: 20px;
	border-top-right-radius: 20px;
}

.type-disable {
	cursor: not-allowed;
}

.MuiOutlinedInput-notchedOutline {
	border-radius: 14px;
}

.step-2-form-badge {
	font-weight: 500;
	font-size: 12px;
	width: 110px;
	color: #fb8500;
	background: rgba(251, 133, 0, 0.4);
	border-radius: 10px;
	top: 10px;
	right: 5px;
}

.gallary-img {
	height: 300px;
	width: 1300px;
	object-fit: fill;
	border-radius: 20px;
}

.gallary-imgs {
	height: 97px;
	width: 170px;
}

.cover-img-badge {
	position: absolute;
	top: 10px;
	left: 10px;
}

.cover-imgs-badge {
	position: absolute;
	top: 5px;
	left: 5px;
}

.selected-venue {
	background: var(--dark-purple);
	color: white;
}
