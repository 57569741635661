.navbar-wrapper {
	transition: all 0.2s ease-in-out;
	position: relative;
}
.nav-links > a {
	color: var(--light-gray);
	transition: all 0.2s ease-in-out;
}
.nav-btn {
	transition: all 0.2s ease-in-out;
}
.active {
	color: var(--mid-purple);
}
.hamburger-menu {
	display: none;
}
.goto-dash-btn {
	border: 1px solid var(--mid-purple);
	transition: all 0.3s ease-in-out;
}
@media (max-width: 768px) {
	.navbar-wrapper {
		justify-content: space-between;
	}
	.hamburger-menu {
		display: block;
	}
	.nav-links {
		text-align: center;
		top: 5rem;
		position: absolute;
		flex-direction: column;
		left: -50%;
		transform: translate(-50%, -10%);
		background-color: white;
		width: 100%;
		transition: all 0.3s ease-in-out;
	}
	.nav-links a {
		font-size: large;
		padding: 1rem;
	}
	.nav-links a:hover {
		background-color: var(--mid-purple);
		color: white;
	}
	.nav-btn {
		text-align: center;
		top: 20.5rem;
		left: -50%;
		transform: translate(-50%, 0%);
		position: absolute;
		background-color: white;
		width: 100%;
		padding: 1rem;
	}
	.nav-active,
	.nav-active {
		left: 50%;
	}
}
