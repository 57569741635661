.Profile {
  height: 91vh;
  width: 90vw;
}

.profile-nav {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  margin-top: -9vh;
}

.profile-logo {
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
}

.profile-logo > img {
  height: 50px;
}

.profile-items {
  display: flex;
  flex-direction: column;
}

.profile-nav a {
  padding: 0.7rem;
  color: var(--heading);
}

.profile-content {
  flex: 5;
}

.profile-selected {
  background: linear-gradient(180deg, #4a1e9e 0%, #7745d6 100%);
  color: white !important;
  border-radius: 8px;
  font-weight: 500;
}

.white-svg > path {
  fill: white !important;
}

.list-icon {
  width: 20px;
  height: 20px;
}

.list-logout {
  position: absolute;
  bottom: 1.5rem;
  left: 0.8rem;
  color: red !important;
}
