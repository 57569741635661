.flex-column {
	display: flex;
	flex-direction: column;
	align-items: center;
}
.newsletter-loader {
	position: relative;
	bottom: 4rem;
}
@media screen and (max-width: 767px) {
	.flex-column {
		flex-direction: row;
	}

	.flex-column > * {
		margin: 0 8px;
	}
}
