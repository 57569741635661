.contact-form-main-container {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 70vh;
	background: linear-gradient(to bottom, #efe7ff 50%, #ffffff 50%);
	margin-bottom: 10vh;
}

.contact-form {
	display: flex;
	flex-wrap: wrap;
	width: 70vw;
	margin: 0 auto;
	box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
	position: relative;
}

.contact-form h2 {
	font-size: 24px;
	margin-bottom: 20px;
	color: #ffffff;
}

.contact-form ul {
	list-style: none;
	margin: 0;
	padding: 0;
}

.contact-form li {
	font-size: 16px;
	line-height: 1.6;
	margin-bottom: 30px;
	display: flex;
	align-items: center;
}

.contact-form li img {
	margin-right: 10px;
	max-width: 25px;
	max-height: 25px;
}

/* .contact-form i {
  margin-right: 10px;
} */

.contact-form-container {
	width: 60%;
	background-color: #ffffff;
	padding: 40px;
}

.contact-form form {
	display: flex;
	flex-wrap: wrap;
}

.contact-form input,
.contact-form textarea {
	width: 100%;
	padding: 10px;
	margin-bottom: 20px;
	border: 0.5px solid #c7c7c7;
	border-radius: 5px;
	background-color: #fff;
	font-size: 16px;
	line-height: 1.6;
}

.contact-form textarea {
	height: 120px;
}

.contact-form button {
	background-color: #4a1e9e;
	color: #fff;
	border: none;
	border-radius: 5px;
	padding: 10px 20px;
	font-size: 16px;
	cursor: pointer;
}

.contactform-loader {
	left: 40%;
	right: 5%;
	top: 8%;
	bottom: 20%;
	position: absolute;
	margin: 0 auto;
	background: transparent;
	backdrop-filter: blur(5px);
	transition: all 0.3s ease-in-out;
}

/* Desktop Styles */

@media screen and (min-width: 768px) {
	.contact-form {
		justify-content: center;
	}

	.contact-info {
		width: 38%;
		background-color: #4a1e9e;
		color: #fff;
		padding: 40px;
	}

	.contact-form-container {
		width: 60%;
		background-color: #ffffff;
		padding: 40px;
	}

	.contact-form input,
	.contact-form textarea {
		font-size: 18px;
	}
}

/* Mobile Styles */

@media screen and (max-width: 768px) {
	.contact-form {
		flex-direction: column-reverse;
		width: 90vw;
	}

	.contact-info,
	.contact-form-container {
		width: 100%;
		padding: 20px;
		text-align: center;
	}

	.contact-info {
		background-color: #4a1e9e;
		color: #fff;
	}

	.contact-form-container {
		background-color: #ffffff;
	}

	.contact-form input,
	.contact-form textarea {
		font-size: 16px;
	}

	.contact-form li img {
		max-width: 15px;
		max-height: 15px;
	}
}
