.rrp-nav {
	height: 5rem;
}

.rrp-content {
	margin: 0rem;
	padding: 3rem 5rem;
	align-items: center;
	text-align: flex-start;
}
