.email-modal-div {
	background-color: #514949;
	width: 100%;
	height: 100vh;
	top: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 999999;
	background-color: rgba(255, 255, 255, 0.6);
	backdrop-filter: blur(4px);
}
.email-modal {
	background-color: #e5e5e5;
	width: 400px;
	height: 450px;
	border-radius: 20px;
	padding: 0 2rem;
	z-index: 999999;
}
.email-modal-input {
	border-radius: 20px;
	background: #f5f5f5;
}
