.video-component {
	background-color: var(--light-purple);
	padding-top: 40px;
	padding-bottom: 40px;
	height: 70vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.heading {
	color: var(--dark-gray);
	margin-bottom: 3rem;
	font-weight: bold;
	font-size: xx-large;
}

.video-wrapper {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	padding-left: 20px;
	padding-right: 20px;
}

.video-container {
	margin-right: 20px;
	transition: all 0.3s ease;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

.video-container:hover {
	transform: scale(1.15);
	box-shadow: 0px 5px 20px rgba(0, 0, 5px, 0.9);
}

iframe {
	width: 560px;
	height: 315px;
}

.text-container {
	flex: 1;
	max-width: 400px;
	margin-left: 3rem;
}

.description {
	font-size: 20px;
	color: var(--mid-gray);
}

@media (max-width: 768px) {
	.video-component {
		padding: 3rem 20px;
		height: auto;
	}
	.video-wrapper {
		flex-direction: column;
		padding: 0;
	}
	.video-container {
		margin-right: 0;
		margin-bottom: 20px;
	}

	iframe {
		width: 100%;
		height: 200px; /* adjust height as needed */
	}

	.text-container {
		flex: 1;
		max-width: 250px;
	}

	.description {
		display: none;
	}
}
