.phone-otp-modal-div {
	background-color: #514949;
	width: 100%;
	height: 100vh;
	top: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 999999;
	background-color: rgba(255, 255, 255, 0.6);
	backdrop-filter: blur(4px);
}
.phone-otp-modal {
	background-color: #e5e5e5;
	width: 400px;
	height: 450px;
	border-radius: 20px;
	padding: 0 2rem;
	z-index: 999999;
}

.profile-phone-otp {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 8px;
}
.login-phone-otp input {
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	caret-color: var(--mid-purple);
	outline-color: var(--mid-purple);
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
