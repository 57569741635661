.login-email-otp-wrapper {
	background-color: rgba(0, 0, 0, 0.3);
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
}
.login-email-otp-form {
	background-color: #e5e5e5;
	width: 400px;
	height: 480px;
	border-radius: 20px;
	padding: 0 2rem;
}

.login-email-otp {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 8px;
}
.login-email-otp input {
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	caret-color: var(--mid-purple);
	outline-color: var(--mid-purple);
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
