.parent-card {
	background-color: #fff;
	padding-top: 40px;
	padding-bottom: 40px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.heading {
	color: var(--heading);
	margin-bottom: 3rem;
	font-weight: bold;
}

.team-details {
	display: flex;
	flex-wrap: wrap;
	width: 80vw;
	justify-content: space-evenly;
	align-items: center;
}
