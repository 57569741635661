.Pricing {
	padding-top: 9rem;
}
.main-card {
	background-color: var(--card-bg);
	width: 320px;
	height: 535px;
	padding: 30px;
	border-radius: 20px;
	transition: all 0.3s ease-in-out;
}
.pricing-btn {
	width: 90%;
}
.plus-card {
	width: 235px;
	height: 372px;
	padding: 30px;
	border-radius: 15px;
	transition: all 0.3s ease-in-out;
}
.recommend-tag {
	position: absolute;
	right: -1.9rem;
	background: linear-gradient(99.38deg, #fb8500 30.6%, #fdb704 72.76%);
	border-radius: 30px 0px 0px 30px;
}
.plan-btn {
	width: 95%;
}
.pro-card {
	width: 235px;
	height: 314px;
	border-radius: 15px;
	padding: 10px;
	transition: all 0.3s ease-in-out;
}
.pro-btn {
	width: 80%;
}
.main-card:hover,
.plus-card:hover,
.pro-card:hover {
	background-color: var(--dark-purple);
	color: white;
	background: #4a1e9e;
	box-shadow: 0px 4px 50px rgba(138, 43, 226, 0.6);
	border-radius: 20px;
}
.main-card:hover h1,
.main-card:hover p,
.main-card:hover span,
.plus-card:hover h3,
.plus-card:hover p,
.plus-card:hover span,
.pro-card:hover h3,
.pro-card:hover p,
.pro-card:hover span {
	color: white;
}
.pro-card:hover strike,
.main-card:hover strike,
.plus-card:hover strike {
	color: #aeaeae;
}
.main-card:hover Button,
.plus-card:hover:hover Button,
.pro-card:hover Button {
	background-color: white !important;
	color: var(--dark-purple) !important;
	box-shadow: 4px 4px 15px rgba(74, 30, 158, 0.15),
		inset 0px 4px 4px rgba(14, 6, 6, 0.2);
}
.main-card:hover svg {
	fill: #fff !important;
}
.main-card:hover svg path,
.plus-card:hover svg path {
	fill: #fff !important;
}
