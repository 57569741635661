.EditProfile {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
  }
  
  .editprofile-form-div input {
	font-weight: 500;
	font-size: 14px;
	color: #2b2b2b;
  }
  
  .editprofile-form-div label {
	font-weight: 500;
	font-size: 14px;
	color: #2b2b2b;
  }
  
  .editprofile-form {
	background: rgba(255, 255, 255, 0.7);
	border-radius: 10px;
	width: 600px;
	font-weight: 500;
	font-size: 16px;
  }
  
  .avatar-div {
	height: 70px;
	width: 70px;
	object-fit: contain;
  }
  
  .avatar-div > img {
	object-position: center;
	object-fit: fill;
	height: 70px;
	width: 70px;
	object-fit: contain;
  }
  
  .avatar-edit {
	background: white;
	position: absolute;
	bottom: -3px;
	right: -3px;
  }
  
  .no-avatar {
	background-color: white;
	height: 70px;
	width: 70px;
	object-fit: contain;
	border-radius: 50%;
	font-size: medium;
	display: flex;
	align-items: center;
	justify-content: center;
  }
  
  