/* Change text color to 7d7d7d */
/* body {
	color: #7d7d7d;
} */

/* Change link color to 7d7d7d */
a {
	color: #7d7d7d;
}

/* Change footer text color to 7d7d7d */
/* footer {
	color: #7d7d7d;
} */

/* Change heading color to 7d7d7d */
/* h1,
h2,
h3,
h4,
h5,
h6 {
	color: #7d7d7d;
} */

/* Change list item text color to 7d7d7d */
li {
	color: #7d7d7d;
	text-align: left;
}

/* Footer Section */

footer .container {
	max-width: 1200px;
	margin: 1rem auto;
}

footer .row {
	display: flex;
	justify-content: space-between;
}

footer .col-md-3 {
	width: 23%;
}

footer .col-md-3 h6 {
	font-size: 18px;
	font-weight: bold;
	margin-bottom: 20px;
}

footer .col-md-3 p {
	margin-top: 0;
}

footer .list-unstyled {
	margin: 0;
	padding: 0;
	list-style: none;
}

footer .list-unstyled li {
	margin-bottom: 10px;
}

footer .list-unstyled a {
	color: var(--mid-gray);
	text-decoration: none;
}

footer .list-unstyled a:hover {
	color: #212529;
}

footer .social-media-icons {
	display: flex;
	margin-bottom: 20px;
}

footer .social-media-icons li {
	margin-right: 10px;
}

footer .social-media-icons img {
	width: 30px;
}

footer hr {
	margin-top: 30px;
	margin-bottom: 30px;
	border-top: 1px solid #dee2e6;
}

footer p {
	font-size: 14px;
}

footer .m-0 {
	margin: 0;
}

footer .d-flex {
	display: flex;
	align-items: center;
}

footer .me-2 {
	margin-right: 10px;
	height: 40px;
	margin-bottom: 1rem;
}

footer .justify-content-between {
	justify-content: space-between;
}

footer .align-items-center {
	align-items: center;
}

@media (max-width: 767px) {
	footer .row {
		flex-direction: column;
		align-items: flex-start;
		padding-left: 30px;
	}

	footer .col-md-3 {
		flex-basis: 100%;
		width: 90%;
		max-width: 100%;
		margin-bottom: 30px;
	}

	footer .social-media-icons img {
		width: 30px;
	}

	footer .social-media-icons li {
		margin-right: 10px;
	}

	footer .social-media-icons li:last-child {
		margin-right: 0;
	}

	footer .justify-content-between {
		flex-direction: column;
		align-items: flex-start;
		padding-left: 30px;
	}

	footer .justify-content-between .d-flex {
		margin-top: 15px;
	}
}
