.login-password-wrapper {
	background-color: rgba(0, 0, 0, 0.3);
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
}
.login-password-form {
	background-color: #e5e5e5;
	width: 400px;
	height: 450px;
	border-radius: 20px;
	padding: 0 2rem;
}
.login-password {
	border-radius: 20px;
	background: #f5f5f5;
}
