.signup-wrapper {
	background-color: rgba(0, 0, 0, 0.3);
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
}
.signup-form {
	background-color: #e5e5e5;
	width: 400px;
	height: 590px;
	border-radius: 20px;
	padding: 0 2rem;
}
.signup-input {
	border-radius: 20px;
	background: #f5f5f5;
	margin-bottom: 2rem;
}
