.notfound-page {
	height: 100vh;
}
.notfound-div {
	position: relative;
}
.notfound-content {
	/* position: absolute; */
	/* top: 20%; */
}
.notfound-image-div {
	/* width: 100%; */
}

.notfound-image {
	/* width: 100%; */
}

@media screen and (max-width: 1000px) {
	.notfound-image {
		/* position: absolute; */
		/* bottom: 0; */
	}
}
